import React, { Fragment, useRef, useState } from "react";
import { RadioGroup, Disclosure, Transition, Dialog } from "@headlessui/react";
import {
	CloudUploadIcon,
	RefreshIcon,
	ExclamationIcon,
	ChevronUpIcon,
	ExternalLinkIcon,
	InformationCircleIcon,
	DocumentReportIcon
} from "@heroicons/react/solid";
import CheckIcon from "../components/CheckIcon";
import Select from "react-select";
import EmotionCacheProvider from "../components/EmotionCacheProvider";

const options = [
	"transcripts",
	"variants"
];

const tissueOptions = [
	{ label: "Adipose - Subcutaneous", value: "adipose_subcutaneous" },
	{ label: "Adipose - Visceral (Omentum)", value: "adipose_visceral_omentum" },
	{ label: "Adrenal Gland", value: "adrenal_gland" },
	{ label: "Artery - Aorta", value: "artery_aorta" },
	{ label: "Artery - Coronary", value: "artery_coronary" },
	{ label: "Artery - Tibial", value: "artery_tibial" },
	{ label: "Bladder", value: "bladder" },
	{ label: "Brain - Amygdala", value: "brain_amygdala" },
	{ label: "Brain - Anterior cingulate cortex (BA24)", value: "brain_anterior_cingulate_cortex_ba24" },
	{ label: "Brain - Caudate (basal ganglia)", value: "brain_caudate_basal_ganglia" },
	{ label: "Brain - Cerebellar Hemisphere", value: "brain_cerebellar_hemisphere" },
	{ label: "Brain - Cerebellum", value: "brain_cerebellum" },
	{ label: "Brain - Cortex", value: "brain_cortex" },
	{ label: "Brain - Frontal Cortex (BA9)", value: "brain_frontal_cortex_ba9" },
	{ label: "Brain - Hippocampus", value: "brain_hippocampus" },
	{ label: "Brain - Hypothalamus", value: "brain_hypothalamus" },
	{ label: "Brain - Nucleus accumbens (basal ganglia)", value: "brain_nucleus_accumbens_basal_ganglia" },
	{ label: "Brain - Putamen (basal ganglia)", value: "brain_putamen_basal_ganglia" },
	{ label: "Brain - Spinal cord (cervical c-1)", value: "brain_spinal_cord_cervical_c_1" },
	{ label: "Brain - Substantia nigra", value: "brain_substantia_nigra" },
	{ label: "Breast - Mammary Tissue", value: "breast_mammary_tissue" },
	{ label: "Cells - EBV-transformed lymphocytes", value: "cells_ebv_transformed_lymphocytes" },
	{ label: "Cells - Transformed fibroblasts", value: "cells_transformed_fibroblasts" },
	{ label: "Cervix - Ectocervix", value: "cervix_ectocervix" },
	{ label: "Cervix - Endocervix", value: "cervix_endocervix" },
	{ label: "Colon - Sigmoid", value: "colon_sigmoid" },
	{ label: "Colon - Transverse", value: "colon_transverse" },
	{ label: "Esophagus - Gastroesophageal Junction", value: "esophagus_gastroesophageal_junction" },
	{ label: "Esophagus - Mucosa", value: "esophagus_mucosa" },
	{ label: "Esophagus - Muscularis", value: "esophagus_muscularis" },
	{ label: "Fallopian Tube", value: "fallopian_tube" },
	{ label: "Heart - Atrial Appendage", value: "heart_atrial_appendage" },
	{ label: "Heart - Left Ventricle", value: "heart_left_ventricle" },
	{ label: "Kidney - Cortex", value: "kidney_cortex" },
	{ label: "Liver", value: "liver" },
	{ label: "Lung", value: "lung" },
	{ label: "Minor Salivary Gland", value: "minor_salivary_gland" },
	{ label: "Muscle - Skeletal", value: "muscle_skeletal" },
	{ label: "Nerve - Tibial", value: "nerve_tibial" },
	{ label: "Ovary", value: "ovary" },
	{ label: "Pancreas", value: "pancreas" },
	{ label: "Pituitary", value: "pituitary" },
	{ label: "Prostate", value: "prostate" },
	{ label: "Skin - Not Sun Exposed (Suprapubic)", value: "skin_not_sun_exposed_suprapubic" },
	{ label: "Skin - Sun Exposed (Lower leg)", value: "skin_sun_exposed_lower_leg" },
	{ label: "Small Intestine - Terminal Ileum", value: "small_intestine_terminal_ileum" },
	{ label: "Spleen", value: "spleen" },
	{ label: "Stomach", value: "stomach" },
	{ label: "Testis", value: "testis" },
	{ label: "Thyroid", value: "thyroid" },
	{ label: "Uterus", value: "uterus" },
	{ label: "Vagina", value: "vagina" },
	{ label: "Whole Blood", value: "whole_blood" }
];

const defaultTissues = [
	"bladder",
	"brain_cerebellum",
	"brain_cortex",
	"cells_ebv_transformed_lymphocytes",
	"cells_transformed_fibroblasts",
	"heart_left_ventricle",
	"liver",
	"minor_salivary_gland",
	"muscle_skeletal",
	"whole_blood",
	"kidney_cortex",
	"skin_not_sun_exposed_suprapubic",
	"cervix_ectocervix",
	"cervix_endocervix"
];

const defaultHeaders = [
	"ID",
	"Symbol",
	"Name",
	"Chromosome",
	"HGNC ID",
	"OMIM ID",
	"Transcript ID",
	"Canonical",
	"MANE Select",
	"Mean Tissue Expression"
];

const quote = (s) => {
	return `"${s}"`;
};

const Home = () => {
	const [mode, setMode] = useState(null);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [showError, setShowError] = useState(false);
	const [showWarning, setShowWarning] = useState(true);
	const [infoOpen, setInfoOpen] = useState(false);
	const [variantOpen, setVariantOpen] = useState(false);
	const [selectedVariant, setSelectedVariant] = useState(false);
	const [tissues, setTissues] = useState(tissueOptions.filter(t => defaultTissues.includes(t.value)));

	const inputRef = useRef(null);
	const filterRef = useRef(null);

	const handleReset = () => {
		setMode(null);
		setData(null);
		setShowWarning(true);
	};
	const handleSubmitGenes = async () => {
		let inputValue = inputRef.current.value;

		if (!inputValue) {
			setErrorMessage("Please provide a list of gene symbols above");
			setShowError(true);
			return;
		}

		let matches = [...inputValue.matchAll(/[a-zA-Z0-9]+/g)];
		let genes = [];

		if (matches.length === 0) {
			setErrorMessage("No valid gene symbols were provided");
			setShowError(true);
			return;
		}

		for (let match of matches)
			genes.push(match[0]);

		setLoading(true);
		setShowError(false);

		let query = "";

		if (mode === "variants") {
			let filterValue = filterRef.current.value;

			if (filterValue)
				query = `?af=${filterValue}`;
		}

		try {
			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/${mode}${query}`, {
				method: "POST",
				headers: {
					"content-type": "application/json"
				},
				body: JSON.stringify(genes)
			});

			let json = await response.json();

			if (response.status === 200)
				setData(json);
			else {
				setErrorMessage(json.message);
				setShowError(true);
			}
		} catch (err) {
			setErrorMessage("An unknown error occurred");
			setShowError(true);
		}

		setLoading(false);
	};
	const handleShowVariantInfo = variant => {
		setVariantOpen(true);
		setSelectedVariant(variant);
	};
	const handleExport = () => {
		let output = [...defaultHeaders, ...tissues.map(t => t.value)].map(h => quote(h)).join(",") + "\n";

		for (const gene of data.genes) {
			for (const transcript of gene.transcripts) {
				const tissueValues = [];

				for (const tissue of tissues)
					tissueValues.push(transcript.gtexTissueExpression.tissueExpressions.find(t => t.tissue === tissue.value)?.value || 0);

				output += [gene.id, gene.symbol, gene.name, gene.chromosome, gene.hgncId, gene.omimId, transcript.id, transcript.id === gene.canonicalTranscriptId, gene.maneSelectTranscript && transcript.id === gene.maneSelectTranscript.ensemblId, transcript.gtexTissueExpression.mean, ...tissueValues].map(i => quote(i)).join(",");
				output += "\n";
			}
		}

		let elem = document.createElement("a");

		elem.style.display = "none";
		elem.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(output));
		elem.setAttribute("download", "output.csv");

		document.body.appendChild(elem);
		elem.click();
		document.body.removeChild(elem);
	};

	return (
		<div className="pt-12 md:pt-24 flex flex-col justify-center select-none pb-24">
			<div className="max-w-6xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4">
				<h1 className="decoration-clone font-black text-4xl text-indigo-500 my-2">
					Gene Visualiser
				</h1>
				<p className="font-sm font-bold text-gray-500 mb-2 -mt-1">
					An{" "}
					<a href="https://gitlab.com/gene-visualiser" target="_blank" className="text-indigo-500 font-extrabold">
						open source
					</a>
					{" "}tool developed by{" "}
					<a href="https://xela.tech" target="_blank" className="text-indigo-500 font-extrabold">
						Alex&nbsp;Gokhale
					</a>.
				</p>
				<p className="font-bold text-lg text-gray-700">
					A tool to display and download transcript expression & variant data across a panel of genes.
				</p>
				<p className="font-bold text-lg text-gray-700">
					To begin, choose one of the options below, and enter a list of gene symbols.
				</p>
				<div className="border-2 border-indigo-500 bg-white shadow-md rounded-lg p-4 pt-3 pr-3 mt-3">
					<h3 className="decoration-clone font-extrabold text-2xl text-indigo-500 mb-1">
						Please Note:
					</h3>
					<p className="font-medium text-gray-700">
						This tool uses data from GRCh37. This is due to the fact that gnomAD&apos;s API doesn&apos;t currently return tissue expression data for build 38.
						However, the gnomAD API doesn&apos;t return MANE Select transcripts for build 37. In order to provide as much data as possible,
						the MANE Select transcript is fetched from the build 38 data, and only displayed if it matches a transcript present in build 37.
					</p>
				</div>
			</div>
			{data ? (
				<>
					<div className="max-w-6xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 flex gap-2">
						<button onClick={handleReset} className={"rounded-lg bg-indigo-500 p-2 flex-grow flex justify-center items-center h-14 text-md " +
						"text-white font-extrabold hover:bg-indigo-600 transition duration-250 disabled:cursor-not-allowed disabled:bg-indigo-400"}>
							<RefreshIcon className="h-5 w-5 -ml-1 mr-2 inline-block" />
							Start Over
						</button>
						{mode === "transcripts" && (
							<button onClick={handleExport} disabled={data.genes.length === 0} className={"relative rounded-lg bg-indigo-500 p-2 flex-grow flex justify-center items-center h-14 text-md " +
								"text-white font-extrabold hover:bg-indigo-600 transition duration-250 disabled:cursor-not-allowed disabled:bg-indigo-400"}>
								<DocumentReportIcon className="h-5 w-5 -ml-1 mr-2 inline-block" />
								Export to CSV
								<div className="bg-rose-500 rounded-md px-2 p-1 font-bold uppercase text-white text-xs absolute -top-1 -right-1">
									New!
								</div>
							</button>
						)}
					</div>
					{data.errors.length !== 0 && (
						<div className={`max-w-6xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 flex-col ${showWarning ? "flex" : "hidden"}`}>
							<div className="border-2 border-orange-500 bg-white shadow-md rounded-lg p-4 pt-3 pr-3">
								<div className="flex justify-between text-orange-500">
									<h3 className="decoration-clone font-black text-2xl">
										<ExclamationIcon className="inline-block h-6 w-6" />
										Warning
									</h3>
									<svg
										fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => setShowWarning(false)}
										className="h-6 w-6 flex-shrink-0 rounded-md hover:bg-orange-100 hover:cursor-pointer transition duration-250">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
									</svg>
								</div>
								<p className="font-bold text-gray-700 mb-2">
									The following gene symbols couldn&apos;t be processed:
								</p>
								<div className="flex flex-wrap gap-2">
									{data.errors.map(e => {
										return (
											<span key={e} className="py-1 px-2 text-sm text-white font-bold rounded-md bg-orange-500">
												{e}
											</span>
										);
									})}
								</div>
							</div>
						</div>
					)}
					{data.genes.map(gene => {
						return (
							<div key={gene.id} className={`w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 flex flex-col ${mode === "variants" ? "max-w-8xl" : "max-w-6xl"}`}>
								<div className="border-2 border-indigo-500 bg-white shadow-md rounded-lg">
									<Disclosure defaultOpen={true}>
										{({ open }) => (
											<>
												<div className="p-2">
													<Disclosure.Button className="flex p-2 justify-between items-center w-full text-md font-bold text-gray-700 rounded-lg hover:bg-indigo-100 focus:outline-none">
														<span className="text-left">{gene.symbol} &ndash; {gene.name}</span>
														<ChevronUpIcon className={`${open ? "transform rotate-180" : "transform rotate-90"} w-6 h-6 flex-shrink-0 text-indigo-500`} />
													</Disclosure.Button>
												</div>
												<Disclosure.Panel className="text-sm select-text">
													<div className="px-3">
														<div className="flex flex-wrap gap-2">
															<p className="py-1 px-2 text-sm text-white rounded-md bg-indigo-500 sm:w-auto w-full">
																<span className="font-bold select-none mr-2">HGNC ID:</span>
																<span>{gene.hgncId}</span>
															</p>
															<p className="py-1 px-2 text-sm text-white rounded-md bg-indigo-500 sm:w-auto w-full">
																<span className="font-bold select-none mr-2">OMIM ID:</span>
																<span>{gene.omimId}</span>
															</p>
															<p className="py-1 px-2 text-sm text-white rounded-md bg-indigo-500 sm:w-auto w-full">
																<span className="font-bold select-none mr-2">Chromosome:</span>
																<span>{gene.chromosome}</span>
															</p>
															<a
																target="_blank" href={`https://gnomad.broadinstitute.org/gene/${gene.id}`}
																className="py-1 px-2 flex justify-between sm:flex-row flex-row-reverse items-center
																	text-sm font-bold text-white select-none rounded-md bg-indigo-500 sm:w-auto w-full"
															>
																<ExternalLinkIcon className="h-5 w-5 inline-block sm:mr-1"/>
																View on gnomAD
															</a>
															{mode === "transcripts" && (
																<>
																	<p className="py-1 px-2 text-sm text-white rounded-md bg-indigo-500 relative sm:w-auto w-full">
																		<span className="font-bold select-none mr-2">gnomAD pLI:</span>
																		<span>{gene.gnomadConstraint.pli}</span>
																	</p>
																	<p className="py-1 px-2 text-sm text-white rounded-md bg-indigo-500 relative sm:w-auto w-full">
																		<span className="font-bold select-none mr-2">Missense Z:</span>
																		<span>{gene.gnomadConstraint.misZ}</span>
																	</p>
																</>
															)}
														</div>
													</div>
													{mode === "transcripts" && (
														<div className="w-full overflow-x-auto">
															<table className="border-collapse w-full table-auto mt-4 rounded-b-lg overflow-hidden">
																<thead className="border-b-2 border-t-2 border-indigo-500">
																	<tr>
																		<th className="text-gray-700 px-4 py-2">
																			Transcript ID
																		</th>
																		<th className="text-gray-700 px-4 py-2">
																			Mean Tissue Expression
																		</th>
																		<th className="text-gray-700 px-4 py-2">
																			Tissue Specific Expressions
																		</th>
																	</tr>
																</thead>
																<tbody className="text-gray-700">
																	{gene.transcripts.map(transcript => {
																		return (
																			<tr
																				className="text-center odd:bg-indigo-100 font-bold border-b border-indigo-200 last:border-b-0" key={transcript.id}>
																				<td className="p-1">
																					<a className="cursor-pointer relative hover:underline" target="_blank" href={`https://gnomad.broadinstitute.org/transcript/${transcript.id}`}>
																						{transcript.id}
																					</a>
																					{transcript.id === gene.canonicalTranscriptId && (
																						<div className="p-2 bg-indigo-500 mt-2 mx-auto text-white rounded-lg font-bold w-max">
																							<p className="select-none leading-none text-sm">Canonical Transcript</p>
																						</div>
																					)}
																					{gene.maneSelectTranscript && (
																						transcript.id === gene.maneSelectTranscript.ensemblId && (
																							<div className="p-2 bg-rose-400 mt-2 mx-auto text-white rounded-lg font-bold w-max">
																								<p className="select-none leading-none text-sm">MANE Select Transcript</p>
																							</div>
																						)
																					)}
																				</td>
																				<td className="p-1">
																					{transcript.gtexTissueExpression.mean.toFixed(3)}
																				</td>
																				<td className="p-1 px-2">
																					{transcript.gtexTissueExpression.tissueExpressions.sort((a, b) => b.value - a.value).map(tissueExpression => {
																						console.log(tissueExpression);
																						if (tissues.find(t => t.value === tissueExpression.tissue)) {
																							return (
																								<div key={tissueExpression.tissue} className="flex justify-between text-xs">
																									<p className="text-left">
																										{tissues.find(t => t.value === tissueExpression.tissue).label}
																									</p>
																									<p className="text-left font-mono">
																										{tissueExpression.value.toFixed(3)}
																									</p>
																								</div>
																							);
																						}

																						return null;
																					})}
																				</td>
																			</tr>
																		);
																	})}
																</tbody>
															</table>
														</div>
													)}
													{mode === "variants" && (
														<div className="w-full overflow-x-auto">
															<table className="border-collapse w-full table-auto mt-4 rounded-b-lg overflow-hidden">
																<thead className="border-b-2 border-t-2 border-indigo-500">
																	<tr>
																		<th className="text-gray-700 px-4 py-2">
																			Variant ID
																		</th>
																		<th className="text-gray-700 px-4 py-2">
																			Source
																		</th>
																		<th className="text-gray-700 px-4 py-2">
																			HGVS Consequence
																		</th>
																		<th className="text-gray-700 px-4 py-2">
																			VEP Annotation
																		</th>
																		<th className="text-gray-700 px-4 py-2">
																			Clinical Significance
																		</th>
																		<th className="text-gray-700 px-4 py-2">
																			A.F. (Exome)
																		</th>
																		<th className="text-gray-700 px-4 py-2">
																			A.F. (Genome)
																		</th>
																		<th className="text-gray-700 px-4 py-2" />
																	</tr>
																</thead>
																<tbody className="text-gray-700">
																	{gene.variants.map(variant => {
																		return (
																			<tr key={variant.id} className="text-center odd:bg-indigo-100 font-bold border-b border-indigo-200 last:border-b-0">
																				<td className="p-1">
																					<a className="cursor-pointer relative hover:underline group" target="_blank" href={`https://gnomad.broadinstitute.org/variant/${variant.id}`}>
																						{variant.id}
																					</a>
																				</td>
																				<td className="p-1">
																					<div className="flex justify-center flex-wrap gap-1">
																						{variant.exome && (
																							<p className="py-1 px-2 text-sm text-white rounded-md bg-indigo-400">
																								<span>E</span>
																							</p>
																						)}
																						{variant.genome && (
																							<p className="py-1 px-2 text-sm text-white rounded-md bg-rose-400">
																								<span>G</span>
																							</p>
																						)}
																					</div>
																				</td>
																				<td className="p-1">
																					{variant.hgvsc ? variant.hgvsc : variant.hgvsp}
																				</td>
																				<td className="p-1">
																					{variant.consequence ? (
																						variant.consequence.replace("_variant", "").replace(/_/g, " ")
																					) : null}
																				</td>
																				<td className="p-1">
																					{variant.clinicalSignificance}
																				</td>
																				<td className="p-1">
																					{variant.exome ? (
																						variant.exome.alleleFrequency.toFixed(6)
																					) : "N/A"}
																				</td>
																				<td>
																					{variant.genome ? (
																						variant.genome.alleleFrequency.toFixed(6)
																					) : "N/A"}
																				</td>
																				<td>
																					<InformationCircleIcon
																						onClick={() => handleShowVariantInfo(variant)}
																						className="h-6 w-6 text-white flex-shrink-0 rounded-md bg-indigo-500 hover:bg-indigo-600 hover:cursor-pointer transition duration-250"
																					/>
																				</td>
																			</tr>
																		);
																	})}
																</tbody>
															</table>
														</div>
													)}
												</Disclosure.Panel>
											</>
										)}
									</Disclosure>
								</div>
							</div>
						);
					})}
					{mode === "variants" && (
						<Transition appear show={variantOpen} as={Fragment}>
							<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto select-none" onClose={() => setVariantOpen(false)}>
								<div className="min-h-screen px-4 text-center">
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="bg-black opacity-0"
										enterTo="bg-black opacity-50"
										entered="bg-black opacity-50"
										leave="ease-in duration-200"
										leaveFrom="bg-black opacity-50"
										leaveTo="bg-black opacity-0"
									>
										<Dialog.Overlay className="fixed inset-0" />
									</Transition.Child>
									<span className="inline-block h-screen align-middle" aria-hidden="true">
										&#8203;
									</span>
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="opacity-0 scale-95"
										enterTo="opacity-100 scale-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100 scale-100"
										leaveTo="opacity-0 scale-95"
									>
										<div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
											<Dialog.Title as="h3" className="text-lg font-bold flex justify-between leading-6 text-gray-700">
												{selectedVariant.id}
												<svg
													fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => setVariantOpen(false)}
													className="h-6 w-6 flex-shrink-0 rounded-md hover:bg-gray-200 hover:cursor-pointer transition duration-250">
													<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
												</svg>
											</Dialog.Title>
											<div className="mt-3">
												<div className="grid grid-cols-2 gap-4 gap-x-6 select-text">
													<div>
														<h4 className="text-md mb-1 font-bold text-gray-600">
															Exome
														</h4>
														{selectedVariant.exome ? (
															<div className="text-sm text-gray-600">
																<div className="flex justify-between">
																	<p className="font-bold">Allele Count:</p>
																	<p>{selectedVariant.exome.alleleCount}</p>
																</div>
																<div className="flex justify-between">
																	<p className="font-bold">Allele Number:</p>
																	<p>{selectedVariant.exome.alleleNumber}</p>
																</div>
																<div className="flex justify-between">
																	<p className="font-bold">Allele Frequency:</p>
																	<p>{selectedVariant.exome.alleleFrequency.toFixed(6)}</p>
																</div>
																<div className="flex justify-between">
																	<p className="font-bold">Homozygote Count:</p>
																	<p>{selectedVariant.exome.homozygoteCount}</p>
																</div>
															</div>
														) : (
															<p className="italic text-sm">
																Not present
															</p>
														)}
													</div>
													<div>
														<h4 className="text-md mb-1 font-bold text-gray-600">
															Genome
														</h4>
														{selectedVariant.genome ? (
															<div className="text-sm text-gray-600">
																<div className="flex justify-between">
																	<p className="font-bold">Allele Count:</p>
																	<p>{selectedVariant.genome.alleleCount}</p>
																</div>
																<div className="flex justify-between">
																	<p className="font-bold">Allele Number:</p>
																	<p>{selectedVariant.genome.alleleNumber}</p>
																</div>
																<div className="flex justify-between">
																	<p className="font-bold">Allele Frequency:</p>
																	<p>{selectedVariant.genome.alleleFrequency.toFixed(6)}</p>
																</div>
																<div className="flex justify-between">
																	<p className="font-bold">Homozygote Count:</p>
																	<p>{selectedVariant.genome.homozygoteCount}</p>
																</div>
															</div>
														) : (
															<p className="italic text-sm">
																Not present
															</p>
														)}
													</div>
												</div>
											</div>
										</div>
									</Transition.Child>
								</div>
							</Dialog>
						</Transition>
					)}
				</>
			) : (
				<>
					<div className="max-w-6xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4">
						<RadioGroup value={mode} onChange={setMode}>
							<div className="flex flex-col md:flex-row gap-3">
								{options.map(option => (
									<RadioGroup.Option
										disabled={loading}
										key={option}
										value={option}
										className={({ checked }) =>
											`${checked ? "bg-indigo-500 text-white" : "bg-white text-indigo-500 hover:bg-indigo-100"}
                                     relative rounded-lg border-indigo-500 border-2 shadow-md px-5 flex-1 py-4
                                     cursor-pointer flex focus:outline-none transition duration-250 ${loading ? "cursor-not-allowed" : ""}`
										}
									>
										{({ checked }) => (
											<>
												<div className="flex items-center justify-between w-full">
													<div className="flex items-center">
														<div className="text-sm">
															<RadioGroup.Label as="p" className="font-bold">
																{option.substring(0, 1).toUpperCase() + option.substring(1)}
															</RadioGroup.Label>
														</div>
													</div>
													<div className="flex-shrink-0 text-white">
														<CheckIcon className={`ml-2 w-6 h-6 ${checked ? "visible" : "invisible"}`} />
													</div>
												</div>
											</>
										)}
									</RadioGroup.Option>
								))}
							</div>
						</RadioGroup>
					</div>
					{mode && (
						<>
							<div className="max-w-6xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 flex">
								<textarea
									placeholder="Enter a list of gene symbols..." disabled={loading} ref={inputRef}
									className={"shadow-md flex-grow h-32 resize-none rounded-lg border-2 placeholder-gray-400 focus:bg-indigo-100 focus:placeholder-gray-500 " +
									"border-indigo-500 p-3 focus:outline-none text-indigo-500 transition duration-250 font-medium disabled:cursor-not-allowed"}
								/>
							</div>
							{mode === "variants" && (
								<>
									<div className="max-w-6xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 flex flex-row">
										<input
											type="number" min="0" step="0.001" ref={filterRef}
											placeholder="Minimum Allele Frequency" disabled={loading}
											className={"shadow-md flex-grow rounded-l-lg border-2 placeholder-gray-400 focus:bg-indigo-100 focus:placeholder-gray-500 " +
											"border-indigo-500 p-3 focus:outline-none text-indigo-500 transition duration-250 font-medium disabled:cursor-not-allowed"}
										/>
										<div
											onClick={() => setInfoOpen(true)}
											className="py-2 px-3 bg-indigo-500 border-l-0 rounded-r-lg cursor-pointer flex-shrink-0 flex border-2 border-indigo-500 items-center hover:bg-indigo-600"
										>
											<InformationCircleIcon className="h-6 w-6 text-white" />
										</div>
									</div>

									<Transition appear show={infoOpen} as={Fragment}>
										<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto select-none" onClose={() => setInfoOpen(false)}>
											<div className="min-h-screen px-4 text-center">
												<Transition.Child
													as={Fragment}
													enter="ease-out duration-300"
													enterFrom="bg-black opacity-0"
													enterTo="bg-black opacity-50"
													entered="bg-black opacity-50"
													leave="ease-in duration-200"
													leaveFrom="bg-black opacity-50"
													leaveTo="bg-black opacity-0"
												>
													<Dialog.Overlay className="fixed inset-0" />
												</Transition.Child>
												<span className="inline-block h-screen align-middle" aria-hidden="true">
													&#8203;
												</span>
												<Transition.Child
													as={Fragment}
													enter="ease-out duration-300"
													enterFrom="opacity-0 scale-95"
													enterTo="opacity-100 scale-100"
													leave="ease-in duration-200"
													leaveFrom="opacity-100 scale-100"
													leaveTo="opacity-0 scale-95"
												>
													<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
														<Dialog.Title as="h3" className="text-lg font-bold flex justify-between leading-6 text-gray-700">
															Allele Frequency Filter
															<svg
																fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => setInfoOpen(false)}
																className="h-6 w-6 flex-shrink-0 rounded-md hover:bg-gray-200 hover:cursor-pointer transition duration-250">
																<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
															</svg>
														</Dialog.Title>
														<div className="mt-2">
															<p className="text-sm font-medium text-gray-600">
																Only variants above this allele frequency will be returned.
																This value can be left blank or set to 0 to return all variants.
															</p>
														</div>
													</div>
												</Transition.Child>
											</div>
										</Dialog>
									</Transition>
								</>
							)}
							{mode === "transcripts" && (
								<div className="max-w-6xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 flex flex-row relative">
									<EmotionCacheProvider>
										<div className="bg-rose-500 z-10 rounded-md px-2 p-1 font-bold uppercase text-white text-xs absolute -top-1 -right-1">
											New!
										</div>
										<Select
											isMulti
											name="tissues"
											options={tissueOptions}
											value={tissues}
											placeholder="Tissues to display..."
											onChange={newValue => setTissues(newValue)}
											classNames={{
												container: () => "w-full",
												control: () => "rounded-lg shadow-none border-2 border-indigo-500 hover:border-indigo-500 outline-none",
												multiValue: () => "text-gray-900 font-semibold",
												multiValueRemove: () => "bg-transparent hover:text-white hover:bg-red-500",
												menu: () => "rounded-lg border-2 border-indigo-500",
												menuList: () => "rounded-lg",
												option: (state) => `text-sm font-semibold hover:bg-indigo-200 ${state.isFocused ? "bg-indigo-200" : ""}`
											}}
										/>
									</EmotionCacheProvider>
								</div>
							)}
							<div className={`max-w-6xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 ${showError ? "flex" : "hidden"}`}>
								<div className="bg-red-500 p-3 rounded-lg flex-1 text-white font-bold flex items-center justify-between">
									<div className="items-center flex">
										<svg className="h-6 w-6 inline-block mr-2 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
											<path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
										</svg>
										{errorMessage}
									</div>
									<svg
										fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => setShowError(false)}
										className="h-6 w-6 flex-shrink-0 rounded-md hover:bg-red-400 hover:cursor-pointer transition duration-250">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
									</svg>
								</div>
							</div>
							<div className="max-w-6xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 flex">
								<button onClick={handleSubmitGenes} disabled={loading} className={"rounded-lg bg-indigo-500 p-2 flex-grow flex justify-center items-center h-14 text-md " +
								"text-white font-extrabold hover:bg-indigo-600 transition duration-250 disabled:cursor-not-allowed disabled:bg-indigo-400"}>
									{loading ? (
										<>
											<svg className="animate-spin h-5 w-5 -ml-1 mr-2 text-white" fill="none" viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
												<path
													className="" fill="currentColor"
													d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
												/>
											</svg>
											Loading
										</>
									) : (
										<>
											<CloudUploadIcon className="h-5 w-5 -ml-1 mr-2 inline-block" />
											Submit
										</>
									)}
								</button>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default Home;
